import "./App.css";
import {BrowserRouter as Router} from "react-router-dom";
import {useState, useEffect, useContext} from "react";
import {Routes, Route, useNavigate} from "react-router-dom";
import {getPractioners} from "./api";
import {GlobalContext, DEFAULT_STATE} from "./context";
import {DebounceInput} from "react-debounce-input";
import * as _ from "lodash";
import PracPopup from "./components/PractitionerPopup";
import {LoadingIcon} from "./components/loading";
import {BookingPage} from "./components/BookingPage";

const categoryText = [
  "Feeling unmotivated & want to improve productivity?",
  "Feeling anxious depressed or stressed?",
  "I don't know what I need - show me everyone!",
];

export const bookingTime = ["60 minute slot", "30 minute slot"];

const MYNDUP_SBM_BASE_URL = "https://simplybook.me";

// Yes this is one file, it's terrible but it was faster to work with.
// Priority one would definitely by to seperate this out in the proper
// component structure

function InitialSelection() {
  const {globalState, setglobalState} = useContext(GlobalContext);
  const navigate = useNavigate();

  function selectClicked(text) {
    setglobalState({...globalState, initialAnswer: text});
    navigate("/time");
  }

  return (
    <div className="selectionContainer">
      {categoryText.map((text) => (
        <div key={text} className="selection-box">
          <img />
          <h3> {text} </h3>
          <div className="selectButton" onClick={() => selectClicked(text)}>
            <p> Select </p>
          </div>
        </div>
      ))}
    </div>
  );
}

function TimeSelection() {
  const {globalState, setglobalState} = useContext(GlobalContext);
  const navigate = useNavigate();

  function selectClicked(slot) {
    setglobalState({...globalState, timeSlot: slot});
    navigate("/search");
  }

  return (
    <div className="selectionContainer">
      {bookingTime.map((text) => (
        <div key={text} className="selection-box">
          <img />
          <h3> {text} </h3>
          <div className="selectButton" onClick={() => selectClicked(text)}>
            <p> Select </p>
          </div>
        </div>
      ))}
    </div>
  );
}

function getImage(url) {
  return MYNDUP_SBM_BASE_URL + url;
}

function PractionerPreviewBox(props) {
  const practioner = props.practioner;
  const imageUrl = getImage(practioner.picture_preview);
  return (
    <div className="practioner-preview-box" onClick={props.onClick}>
      {" "}
      <img className="practionerPreview" src={imageUrl} />
      <h1 className="practionerTitle"> {practioner.name} </h1>
      <p className="pracitionerMoreInfo"> More info... </p>
      <div className="practionerSelectButton" />
    </div>
  );
}

function FilterControls() {
  // Language
  // Feature: Filter on button press (DEBOUNCE?)
  // Reset Button
  // https://www.npmjs.com/package/react-debounce-input Debounce

  const {globalState, setglobalState} = useContext(GlobalContext);
  let {practioners, initialAnswer, filterOptions, filterConfig} = globalState;

  // TODO (Baz) Filter by the inital answer

  // Can't do anything here yet
  if (!practioners) return;

  const elements = [];


  for (const filterField of Object.keys(filterConfig)) {
    const field = filterConfig[filterField]

    if (field.type === Array) {
      const values = field.values;

      if (!(filterField in filterOptions)) {
        filterOptions[filterField] = [];
      }

      function select(clickedItem) {
        // Easier to work with dicts here to prevent duplicates
        // Dicts can be like Sets when used like this
        if (filterOptions[filterField].includes(clickedItem)) {
          const idx = filterOptions[filterField].indexOf(clickedItem);
          filterOptions[filterField].splice(idx, 1);
        } else {
          filterOptions[filterField].push(clickedItem);
        }

        setglobalState({...globalState, filterOptions});
      }

      const component = (
        <div className="practionerTypes">
          {values.map((x) => {
            let className = filterField + "Button";
            if (filterOptions[filterField].includes(x)) {
              className = "selected" + className;
            }

            return (
              <p
                className={className}
                onClick={() => select(x)}
                key={x + className}
              >
                {x}
              </p>
            );
          })}
        </div>
      );
      elements.push(component);
    }
  }

  function textChanged(text) {
    filterOptions.searchString = text;
    setglobalState({...globalState, filterOptions});
  }

  return (
    <div className="filterControls">
      <DebounceInput
        autoFocus
        minLength={2}
        debounceTimeout={300}
        onChange={(event) => textChanged(event.target.value)}
      />
      {elements}
    </div>
  );
}

/**
 * Filters a given set of practitioners with the string given.
 * * TODO in order of complexity
 *
 * 1. Simple, does this exist
 * 2. TRIE Data Structure
 * 3. Elastic Search
 */
function filteredPractionersByString(practioners, string) {
  const words = _.lowerCase(string).split(" ");
  return practioners.filter((practioner) => {
    for (const key of Object.keys(practioner)) {
      const value = _.lowerCase(practioner[key]);
      for (const word of words) {
        if (typeof value === "string" && value.includes(word)) {
          return true;
        }
      }
    }
  });
}

function SearchPage() {
  const navigate = useNavigate();
  const {globalState, setglobalState} = useContext(GlobalContext);
  let {practioners, searchString, initialAnswer, filterOptions, filterConfig, showPopup, slot} =
    globalState;

  // TODO: Loading Icon
  if (!practioners) return <LoadingIcon />;

  let filteredPractioners = practioners;

  for (const filterKey of Object.keys(filterOptions)) {
    const type = filterConfig[filterKey].type
    // Empty array
    if (filterOptions[filterKey]) continue

    if (type === Array) {
      filteredPractioners = filteredPractioners.filter(x => {
        for (const item in x[filterKey]) {
          if (filterOptions[filterKey].includes(item)) {
            return true
          }
        }
        return false
      })
    } else {
      filteredPractioners = filteredPractioners.filter(x => {
        return filterOptions[filterKey].includes(x[filterKey])
      })
    }
  }

  // Filter Search String Logic
  if (searchString)
    filteredPractioners = filteredPractionersByString(
      filteredPractioners,
      searchString
    );

  async function setPopup(prac) {
    await setglobalState({...globalState, selectedPractitioner: prac});
    const slotString = slot === bookingTime[0] ? "60" : "30";
    const urlNav = `/book/${prac.id}/${slotString}`;
    navigate(urlNav);
  }

  return (
    <div className="searchPage">
      <FilterControls />
      <div className="practionersView">
        {filteredPractioners.map((x) => (
          <PractionerPreviewBox
            onClick={(_) => setPopup(x)}
            practioner={x}
            key={x.name}
          />
        ))}
      </div>
    </div>
  );
}

function setupFiltering(practitioners) {
  /**
   * Returns filtering configuration
   */

  const filteringKeys = [
    ["languages", Array],
    ["primarySpecialisms", Array],
    ["secondarySpecialisms", Array],
  ];

  // Warn if something is missing from the desired configuration
  const keys = Object.keys(practitioners[0]);
  for (const k of filteringKeys) {
    if (!keys.includes(k[0])) {
      console.error("Cannot filter by ", k[0]);
    }
  }

  const arrayKeys = filteringKeys
    .filter((x) => x[1] === Array)
    .map((x) => x[0]);

  const stringKeys = filteringKeys
    .filter((x) => x[1] === String)
    .map((x) => x[0]);

  let filterConfig = {};

  // Flatten Arrays
  for (const k of arrayKeys) {
    let values = practitioners.map((x) => x[k]);
    values = _.flatten(values).sort();
    values = [...new Set(values)];
    filterConfig[k] = {type: Array, values};
  }

  // Get all strings
  for (const k of stringKeys) {
    let values = practitioners.map((x) => x[k]).sort();
    values = [...new Set(values)];
    filterConfig[k] = {type: String, values};
  }

  return filterConfig;
}

function App() {
  const [globalState, setglobalState] = useState(DEFAULT_STATE);

  const downloadPractitioners = async () => {
    const practitioners = await getPractioners();
    // Determine what type of thing is in the key (Array or String)
    // this will determine the way we filter / select the item.
    // This will be done based on the first practitioner.
    const filterConfig = setupFiltering(practitioners);
    setglobalState({
      ...globalState,
      filterConfig,
      practioners: practitioners,
    });
  };

  // Component Did Mount
  useEffect(() => {
    if (!globalState.practioners && !App.globalStateSet) {
      // Annoying hack, this should only be called once but is
      // called twice for some reason
      App.globalStateSet = true;
      downloadPractitioners();
    }
  });

  return (
    <GlobalContext.Provider value={{globalState, setglobalState}}>
      <Router>
        <Routes>
          // Give us some time to load the data
          <Route path="/" element={<InitialSelection />} />
          // Select Time Slot
          <Route path="/time" element={<TimeSelection />} />
          // Search the practitioners available
          <Route path="/search" element={<SearchPage />} />
          // Page that holds the iframe
          <Route
            path="/book/:practionerId/:timeSlot"
            element={<BookingPage />}
          />
        </Routes>
      </Router>
    </GlobalContext.Provider>
  );
}

export default App;
