import { useContext } from "react";
import { GlobalContext } from "../context";
import { useParams } from "react-router-dom";
import { LoadingIcon } from "./loading";

export function BookingPage() {
  const { timeSlot, practionerId } = useParams();
  const { globalState } = useContext(GlobalContext);
  const { practioners } = globalState;
  if (!practioners) return <LoadingIcon />;

  // Select Practitioner
  // const selectedPractitioner = practioners.find((x) => {
  //   console.log(practionerId, x.id);
  //   return x.id === parseInt(practionerId);
  // });

  const url30 =
    "https://myndupcoaching.simplybook.it/v2/?widget-type=iframe&theme=minimal&theme=minimal&timeline=modern_week&datepicker=top_calendar#book/category/1/service/7/count/1/provider/" +
    practionerId;
  const url60 =
    "https://myndupcoaching.simplybook.it/v2/?widget-type=iframe&theme=minimal&theme=minimal&timeline=modern_week&datepicker=top_calendar#book/category/4/service/2/count/1/provider/" +
    practionerId;

  // Determine whether the user wants a 30 minute or 60 minute session
  let url = null;
  if (timeSlot === "60") {
    url = url60;
  } else {
    url = url30;
  }
  return <iframe style={{ width: "100vw", height: "100vh" }} src={url} />;
}
