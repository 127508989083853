import axios from "axios";
const { NODE_ENV } = process.env;
console.log("Environment", NODE_ENV);

let BASE_URL = "https://api.myndup.com";
// if (NODE_ENV === "development") {
//   BASE_URL = "http://localhost:3333";
// }

export async function getPractioners() {
  const response = await axios.get(BASE_URL + "/practitioner/available", {
    timeout: 20 * 1000,
  });

  // Retrieve hidden data
  const practioners = response.data.map((practioner) => {
    const el = document.createElement("html");
    el.innerHTML = practioner.description;
    let json = el.getElementsByClassName("hiddenData");
    if (json.length > 0) {
      try {
        json = JSON.parse(json[0].innerHTML);
        practioner = { ...practioner, ...json };
        practioner.name = practioner.name.replace("&amp;", "&");
        return practioner;
      } catch (e) {
        console.log(
          `Problem parsing practioner ${practioner.name}\n ${e.message}`
        );
      }
    }
    return null;
  });

  const res = practioners.filter((x) => !!x);
  return res;
}
